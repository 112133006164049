import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';
import NavbarUtilisateur from 'components_public/navbarUtilisateur';
import { useGetInfoUtilisateurMutation, useModifyInfoUtilisateurMutation, useDeleteUtilisateurMutation } from 'state/api'; // Ajouter la mutation pour la suppression
import { useTranslation } from 'react-i18next';

const InformationsPersonnel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userAuth.user);
  const userId = user?.ID_Utilisateur;
  const [getUtilisateurInfo, { data: userInfo, isLoading, isError }] = useGetInfoUtilisateurMutation();
  const [updateUtilisateur] = useModifyInfoUtilisateurMutation();
  const [deleteUtilisateur] = useDeleteUtilisateurMutation(); // Mutation pour la suppression

  // États locaux pour gérer les valeurs de formulaire
  const [name, setName] = useState('');
  const [prenom, setPrenom] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  // État pour gérer l'ouverture de la boîte de dialogue de suppression
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (userId) {
      getUtilisateurInfo({ ID_Utilisateur: userId });
    }
  }, [userId, getUtilisateurInfo]);

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setPrenom(userInfo.prenom);
      setPhoneNumber(userInfo.phoneNumber);
    }
  }, [userInfo]);

  const handleEdit = async () => {
    try {
      await updateUtilisateur({ ID_Utilisateur: userId, name, prenom, phoneNumber });
    } catch (error) {
      console.error(t('InformationsPersonnel.error'), error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteUtilisateur({ ID_Utilisateur: userId }); // Appel à la mutation pour supprimer le compte
      setOpenDeleteDialog(false);
      // Déconnecter l'utilisateur ou rediriger vers une autre page après suppression
    } catch (error) {
      console.error(t('InformationsPersonnel.error'), error);
    }
  };

  if (isLoading) return <p>{t('InformationsPersonnel.loading')}</p>;
  if (isError) return <p>{t('InformationsPersonnel.error')}</p>;

  return (
    <Container>
      <NavbarUtilisateur />
      <Typography variant="h3" gutterBottom component="div" style={{ marginTop: '20px' }}>
        {t('InformationsPersonnel.title')}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '20px' }}>
        {t('InformationsPersonnel.description')}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '20px' }}>
        {t('InformationsPersonnel.emailLabel')}: {userInfo?.email}
      </Typography>
      <TextField
        label={t('InformationsPersonnel.nameLabel')}
        value={name}
        onChange={e => setName(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        label={t('InformationsPersonnel.prenomLabel')}
        value={prenom}
        onChange={e => setPrenom(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        label={t('InformationsPersonnel.phoneLabel')}
        value={phoneNumber}
        onChange={e => setPhoneNumber(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleEdit} sx={{ padding: '10px 20px', fontSize: '16px' }}>
          {t('InformationsPersonnel.editButton')}
        </Button>
        {/* Bouton pour supprimer le compte */}
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpenDeleteDialog(true)}
          sx={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#d32f2f',
            '&:hover': {
              backgroundColor: '#b71c1c',
              boxShadow: '0 4px 20px rgba(255, 0, 0, 0.4)',
            },
          }}
        >
          {t('InformationsPersonnel.deleteAccountButton')}
        </Button>
      </Box>

      {/* Dialog pour confirmation de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: 'white', backgroundColor: '#d32f2f', fontWeight: 'bold', fontSize: '22px' }}
        >
          {t('InformationsPersonnel.deleteAccountTitle')}
        </DialogTitle>
        <DialogContent sx={{ padding: '20px', backgroundColor: '#fbe9e7' }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: '#b71c1c', fontWeight: 'bold', fontSize: '18px' }}
          >
            {t('InformationsPersonnel.deleteAccountWarning')}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: '#fbe9e7', padding: '10px 20px' }}>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            color="primary"
            sx={{
              fontSize: '16px',
              padding: '8px 16px',
              color: '#00796b',
              '&:hover': {
                backgroundColor: '#e0f2f1',
                boxShadow: '0 4px 10px rgba(0, 121, 107, 0.2)',
              },
            }}
          >
            {t('InformationsPersonnel.cancelButton')}
          </Button>
          <Button
            onClick={handleDeleteAccount}
            color="error"
            sx={{
              fontSize: '16px',
              padding: '8px 16px',
              color: '#d32f2f',
              '&:hover': {
                backgroundColor: '#ffcdd2',
                boxShadow: '0 4px 10px rgba(211, 47, 47, 0.4)',
              },
            }}
          >
            {t('InformationsPersonnel.deleteButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default InformationsPersonnel;
