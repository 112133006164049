import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Paper, Typography, Box, CircularProgress, Snackbar, Divider, Dialog, DialogContent, DialogTitle, IconButton, TextField, Alert } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import NavbarUtilisateur from 'components_public/navbarUtilisateur';
import { useLoginUtilisateurEtablissementMutation, useUpdateSessionUtilisateurMutation, useGestionAbonnementStripeMutation } from 'state/api';
import { setAdminAuthenticated } from 'state/adminAuth';
import { updateSessionData } from 'state/userAuth';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Design helpers
const buttonStyle = (color1, color2) => ({
  background: `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`,
  color: 'white',
  fontWeight: 'bold',
  fontSize: '16px',
  padding: '12px 24px',
  marginTop: '20px',
  letterSpacing: '0.2rem',
  textTransform: 'uppercase',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  width: '100%',
});

const statusStyle = (color) => ({
  padding: '5px 10px',
  borderRadius: '15px',
  border: `1px solid ${color}`,
  color: color,
  fontWeight: 'bold',
  position: 'absolute',
  top: '10px',
  left: '10px',
  fontSize: '12px',
});

const ListeEtablissement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const etablissements = useSelector(state => state.userAuth.etablissements || []);
  const userId = useSelector(state => state.userAuth.user?.ID_Utilisateur);
  const { isAuthenticated, user } = useSelector((state) => state.userAuth);
  const userEmail = user ? user.email : ''; // Vérifie que user n'est pas null

  const [loginEtablissement, { isLoading: isLoadingLogin }] = useLoginUtilisateurEtablissementMutation();
  const [updateSessionUtilisateur, { isLoading: isLoadingUpdate }] = useUpdateSessionUtilisateurMutation();
  const [gestionAbonnementStripe] = useGestionAbonnementStripeMutation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSessionLoaded, setSessionLoaded] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openJoinDialog, setOpenJoinDialog] = useState(false);
  const [entryKey, setEntryKey] = useState('');

  useEffect(() => {
    if (userId && !isSessionLoaded) {
      handleUpdateSession();
    }
  }, [userId, isSessionLoaded]);

  const handleUpdateSession = async () => {
    try {
      const response = await updateSessionUtilisateur({ ID_Utilisateur: userId }).unwrap();
      dispatch(updateSessionData(response.utilisateur));
      setSessionLoaded(true);
    } catch (error) {
      console.error(t('ListeEtablissement.SnackbarError'));
      setSnackbarMessage(t('ListeEtablissement.SnackbarError'));
      setOpenSnackbar(true);
    }
  };

  const handleSelectEtablissement = async (ID_Etablissement) => {
    try {
      const response = await loginEtablissement({ ID_Utilisateur: userId, ID_Etablissement }).unwrap();
      dispatch(setAdminAuthenticated({
        isAuthenticated: true,
        ID_Etablissement: response.etablissement.ID_Etablissement,
        roleInfo: response.role,
        abonnementType: response.etablissement.subscriptionType,
      }));
      navigate('/acceuil');
    } catch (error) {
      console.error(t('ListeEtablissement.SnackbarError'), error);
      setSnackbarMessage(t('ListeEtablissement.SnackbarError'));
      setOpenSnackbar(true);
    }
  };

  const handleCreateDialogOpen = () => setOpenCreateDialog(true);
  const handleCreateDialogClose = () => setOpenCreateDialog(false);

  const handleJoinDialogOpen = () => setOpenJoinDialog(true);
  const handleJoinDialogClose = () => setOpenJoinDialog(false);

  const handleSubscription = (newSubscriptionType) => {
    try {
      navigate(`/souscription-etablissement/${newSubscriptionType}`);
    } catch (error) {
      setSnackbarMessage(t('ListeEtablissement.ErrorSubscription'));
      setOpenSnackbar(true);
    }
  };

  const handleManageSubscriptionDialogOpen = async (etablissement) => {
    try {
      const response = await gestionAbonnementStripe({
        customerEmail: userEmail,
        returnUrl: window.location.href
      }).unwrap();

      if (response.url) {
        window.location.href = response.url;
      } else {
        console.error(t('ListeEtablissement.NoBillingPortal'));
      }
    } catch (error) {
      console.error(t('ListeEtablissement.BillingPortalError'), error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
  };

  // Séparation des établissements en deux catégories
  const etablissementsProprietaire = etablissements.filter(e => e.ID_Utilisateur_Proprietaire === userId);
  const etablissementsCollaborateur = etablissements.filter(e => e.ID_Utilisateur_Proprietaire !== userId || !e.ID_Utilisateur_Proprietaire);

  // Vérifie si l'établissement est actif ou annulé ou annulé pour une date future
  const isEtablissementInactive = (isActive, cancelledDate) => {
    if (!isActive && cancelledDate) {
      const currentDate = moment();
      return moment(cancelledDate).isBefore(currentDate);
    }
    return false;
  };

  const isEtablissementEndingSoon = (isActive, cancelledDate) => {
    if (!isActive && cancelledDate) {
      const currentDate = moment();
      return moment(cancelledDate).isAfter(currentDate);
    }
    return false;
  };

  return (
    <Box sx={{ width: '100vw', height: '100vh', overflowY: 'auto' }}>
      <NavbarUtilisateur />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflowY: 'auto',
        }}
      >
        <Container maxWidth="md" sx={{ marginTop: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleUpdateSession}
            disabled={isLoadingUpdate}
          >
            {t('ListeEtablissement.ButtonRefresh')}
          </Button>
        </Container>

        <Container maxWidth="md" sx={{ marginTop: 4 }}>
          {isLoadingUpdate && <CircularProgress />}

          {/* Section Vos Etablissements */}
          <Typography variant="h5" gutterBottom>
            {t('ListeEtablissement.YourEstablishments')}
          </Typography>
          <Divider sx={{ marginBottom: 2, width: '100%' }} />
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {etablissementsProprietaire.length > 0 ? (
              etablissementsProprietaire.map((etablissement, index) => {
                const isActive = etablissement.isActive;
                const cancelledDate = etablissement.CancelledDate;
                const inactif = isEtablissementInactive(isActive, cancelledDate);
                const endingSoon = isEtablissementEndingSoon(isActive, cancelledDate);

                return (
                  <Grid item xs={12} sm={8} md={6} key={index}>
                    <Paper elevation={3} sx={{ padding: 4, width: '100%', minHeight: 200, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      {/* Affichage du statut de l'établissement en haut à gauche de manière discrète */}
                      <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
                        {isActive ? (
                          <Typography sx={statusStyle('green')}>{t('ListeEtablissement.Active')}</Typography>
                        ) : inactif ? (
                          <Typography sx={statusStyle('red')}>{t('ListeEtablissement.Inactive')}</Typography>
                        ) : endingSoon ? (
                          <Typography sx={statusStyle('orange')}>{t('ListeEtablissement.ActiveUntil', { date: moment(cancelledDate).format('DD/MM/YYYY') })}</Typography>
                        ) : null}
                      </Box>

                      {/* Ligne contenant le titre et le bouton engrenage */}
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginBottom: 2 }}>
                        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', flexGrow: 1 }}>
                          {etablissement.Nom_Etablissement}
                        </Typography>
                        {/* Position absolute pour que le bouton engrenage n'affecte pas la largeur du conteneur */}
                        <IconButton onClick={() => handleManageSubscriptionDialogOpen(etablissement)} sx={{ position: 'absolute', right: 0, top: 0, color: 'gray' }}>
                          <SettingsIcon />
                        </IconButton>
                      </Box>
                      
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => handleSelectEtablissement(etablissement.ID_Etablissement)}
                        disabled={inactif || isLoadingLogin}
                        sx={{ marginTop: 2 }}
                      >
                        {t('ListeEtablissement.ButtonConnexion')}
                      </Button>

                      {/* Avertissement si l'abonnement prendra fin bientôt */}
                      {endingSoon && (
                        <Alert severity="warning" sx={{ marginTop: 2 }}>
                          {t('ListeEtablissement.AlertEndingSoon', { date: moment(cancelledDate).format('DD/MM/YYYY') })}
                        </Alert>
                      )}
                    </Paper>
                  </Grid>
                );
              })
            ) : (
              <Typography variant="h6" color="textSecondary">
                {t('ListeEtablissement.NoEstablishmentOwned')}
              </Typography>
            )}

            {/* Ajout de la case pour créer un établissement */}
            <Grid item xs={12} sm={8} md={6}>
              <Paper elevation={3} sx={{ padding: 4, width: '100%', minHeight: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton onClick={handleCreateDialogOpen}>
                  <AddCircleOutlineIcon sx={{ fontSize: 60, color: 'white', backgroundColor: 'black', borderRadius: '50%' }} />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>

          {/* Section Vos Collaborations */}
          <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
            {t('ListeEtablissement.YourCollaborations')}
          </Typography>
          <Divider sx={{ marginBottom: 2, width: '100%' }} />
          <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginBottom: 4 }}>
            {etablissementsCollaborateur.length > 0 ? (
              etablissementsCollaborateur.map((etablissement, index) => (
                <Grid item xs={12} sm={8} md={6} key={index}>
                  <Paper elevation={3} sx={{ padding: 4, width: '100%', minHeight: 200, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {/* Affichage du statut de l'établissement en haut à gauche */}
                    <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
                      <Typography sx={statusStyle('green')}>{t('ListeEtablissement.Collaborator')}</Typography>
                    </Box>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', flexGrow: 1 }}>
                        {etablissement.Nom_Etablissement}
                      </Typography>
                    </Box>

                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => handleSelectEtablissement(etablissement.ID_Etablissement)}
                      disabled={isLoadingLogin}
                      sx={{ marginTop: 2 }}
                    >
                      {t('ListeEtablissement.ButtonConnexion')}
                    </Button>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Typography variant="h6" color="textSecondary">
                {t('ListeEtablissement.NoCollaborationsFound')}
              </Typography>
            )}

            {/* Ajout de la case pour rejoindre un établissement */}
            <Grid item xs={12} sm={8} md={6}>
              <Paper elevation={3} sx={{ padding: 4, width: '100%', minHeight: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton onClick={handleJoinDialogOpen}>
                  <AddCircleOutlineIcon sx={{ fontSize: 60, color: 'white', backgroundColor: 'black', borderRadius: '50%' }} />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Snackbar for errors */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />

      {/* Dialog pour créer un établissement */}
      <Dialog open={openCreateDialog} onClose={handleCreateDialogClose}>
        <DialogTitle sx={{ fontSize: '24px', fontWeight: 'bold' }}>{t('ListeEtablissement.CreateEstablishmentDialogTitle')}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Button sx={buttonStyle('#FF6347', '#FF4500')} onClick={() => handleSubscription('proprietaire')}>
              {t('ListeEtablissement.ButtonCreateProprietor')}
            </Button>
            <Button sx={buttonStyle('#4682B4', '#1E90FF')} onClick={() => handleSubscription('entreprise')}>
              {t('ListeEtablissement.ButtonCreateEnterprise')}
            </Button>
            <Button sx={buttonStyle('#32CD32', '#228B22')} onClick={() => handleSubscription('company')}>
              {t('ListeEtablissement.ButtonCreateCompany')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Dialog pour rejoindre un établissement */}
      <Dialog open={openJoinDialog} onClose={handleJoinDialogClose}>
        <DialogTitle sx={{ fontSize: '24px', fontWeight: 'bold' }}>{t('ListeEtablissement.CreateCollaborationDialogTitle')}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <TextField
              label={t('ListeEtablissement.CollaborationPlaceholder')}
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={entryKey}
              onChange={(e) => setEntryKey(e.target.value)}
            />
            <Button sx={buttonStyle('#32CD32', '#228B22')} onClick={() => console.log('Joining with key:', entryKey)}>
              {t('ListeEtablissement.ButtonJoin')}
            </Button>
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2, textAlign: 'center' }}>
              {t('ListeEtablissement.ContactAdminForInvite')}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ListeEtablissement;
