import React, { useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import Header from "components/Header";
import RenderRole from "components/RenderRole";
import RenderUtilisateur from "components/RenderUtilisateurs";
import RenderRegle from "components/RenderRegle";
import RenderGroupe from "components/RenderGroupe";
import { useTranslation } from "react-i18next";

const Admin = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('roles');

  const renderSection = () => {
    switch (activeSection) {
      case 'roles':
        return <RenderRole />;
      case 'groupes':
        return <RenderGroupe />;
      case 'administrateurs':
        return <RenderUtilisateur />;
      case 'regles':
        return <RenderRegle />;
      default:
        return <RenderRole />;
    }
  };

  const buttonStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '10px',
    padding: '15px 30px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('Admin.Title')} subtitle={t('Admin.Subtitle')} />

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '50px' }}>
        <Button sx={buttonStyle} onClick={() => setActiveSection('roles')}>{t('Admin.Roles')}</Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('groupes')}>{t('Admin.Groups')}</Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('administrateurs')}>{t('Admin.Administrators')}</Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('regles')}>{t('Admin.Rules')}</Button>
      </Box>

      {renderSection()}
    </Box>
  );
};

export default Admin;
