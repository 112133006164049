import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useGetPublishedNotificationMutation } from "state/api";
import { useSelector } from "react-redux";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const NotificationAcceuil = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [animationState, setAnimationState] = useState("in");
  const [loading, setLoading] = useState(true);
  const [getPublishedNotifications] = useGetPublishedNotificationMutation();
  const userSession = useSelector((state) => state.adminAuth);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getPublishedNotifications().unwrap();
      setNotifications(response);
      setLoading(false);
    } catch (error) {
      console.error(t('fetch_error_notifications'), error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [getPublishedNotifications]);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationState("out");

      setTimeout(() => {
        setCurrentNotificationIndex(
          (prevIndex) => (prevIndex + 1) % notifications.length
        );
        setAnimationState("in");
      }, 500);
    }, 45000);

    return () => clearInterval(interval);
  }, [notifications]);

  if (loading) {
    return (
      <Box
        bgcolor="primary.main"
        color="white"
        p={2}
        borderRadius={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (notifications.length === 0) {
    return null;
  }

  return (
    <Box
      bgcolor="primary.main"
      color="white"
      p={2}
      borderRadius={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      width="100%"
      height="100%"
      overflow="hidden"
    >
      <Box
        sx={{
          animation: `${animationState === "in" ? slideIn : slideOut} 0.5s ease-in-out`,
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "right",
          }}
        >
          {notifications[currentNotificationIndex].Nom_Notification}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.875rem",
            marginTop: "10px",
            whiteSpace: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "left",
          }}
        >
          {notifications[currentNotificationIndex].Contenu}
        </Typography>
      </Box>
    </Box>
  );
};

export default NotificationAcceuil;
