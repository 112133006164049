import React, { useEffect } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Importation du hook useNavigate

const HeroSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialisation de useNavigate

  useEffect(() => {
    // Empêcher le défilement de la page lorsque le composant est monté
    document.body.style.overflow = 'hidden';
    
    return () => {
      // Rétablir le défilement quand le composant est démonté
      document.body.style.overflow = '';
    };
  }, []);

  const handleSignUpClick = () => {
    navigate('/abonnements'); // Redirection vers la route /abonnements
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  // Animation pour les rayons avec un cycle fluide
  const rayAnimation = {
    hidden: { opacity: 0.5, height: '100%' },
    visible: (i) => ({
      opacity: [0.5, 1, 0.5],
      height: ['100%', '150%', '100%'],
      transition: {
        duration: 4,
        delay: i * 0.5,
        repeat: Infinity,
        ease: 'easeInOut',
      },
    }),
  };

  const buttonHover = {
    scale: 1.05,
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  return (
    <Box
      sx={{
        height: '100vh',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#001F54',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {/* Deuxième couche: Lumière rose/violet très légère */}
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'radial-gradient(circle at center bottom, rgba(255,105,180,0.2), transparent 70%)',
          zIndex: 1,
        }}
      />

      {/* Trois couches de rayons couvrant tout le cercle */}
      {[...Array(3)].map((_, layerIndex) => (
        <Box
          key={layerIndex}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            zIndex: 2,
          }}
        >
          {[...Array(12)].map((_, rayIndex) => (
            <motion.div
              key={rayIndex}
              custom={rayIndex}
              initial="hidden"
              animate="visible"
              variants={rayAnimation}
              style={{
                position: 'absolute',
                width: '5px',
                background: `rgba(255,255,255,${0.3 + layerIndex * 0.1})`,
                transform: `rotate(${rayIndex * 30 + layerIndex * 10}deg) translateX(-50%)`,
                transformOrigin: 'bottom center',
                bottom: '-20px',
                filter: 'blur(4px)',
              }}
            />
          ))}
        </Box>
      ))}

      {/* Grand demi-cercle du soleil complètement blanc sans dégradé */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '350px',
          height: '175px',
          backgroundColor: 'rgba(255,255,255,1)', // Supprime le dégradé
          borderRadius: '175px 175px 0 0',
          filter: 'blur(8px)', // Toujours légèrement flouté
          zIndex: 3,
        }}
      />

      {/* Texte et bouton */}
      <Container
        maxWidth="md"
        sx={{
          textAlign: 'center',
          zIndex: 5,
        }}
      >
        <motion.div initial="hidden" animate="visible" variants={fadeInUp}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              letterSpacing: '1.4rem',
              lineHeight: 1.5,
              color: '#FFFFFF',
            }}
          >
            {t('HeroSection.Title')}
          </Typography>
          <Typography
            variant="h6"
            sx={{ mb: 4, color: '#E0E0E0', fontSize: '1.2rem' }}
          >
            {t('HeroSection.Subtitle')}
          </Typography>
          <motion.div
            whileHover={buttonHover}
            style={{ display: 'inline-block' }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: '#FFFFFF',
                color: '#001F54',
                minWidth: '200px',
                padding: '12px 24px',
                letterSpacing: '0.2rem',
                fontSize: '1.2rem',
                borderRadius: '50px',
                border: '2px solid #001F54',
                transition: 'transform 0.2s ease-in-out, background-color 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
              onClick={handleSignUpClick} // Appel à la fonction de redirection
            >
              {t('HeroSection.SignUpButton')}
            </Button>
          </motion.div>
        </motion.div>
      </Container>
    </Box>
  );
};

export default HeroSection;
