import React, { useState } from "react";
import { Box, useTheme, Button, IconButton } from "@mui/material";
import Header from "components/Header";
import RenderTypeDactionIT from "components/informatique/RenderTypeDactionIT";
import RenderIncidentIT from "components/informatique/RenderIncidentIT";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const IncidentIT = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('incident');
  const [isExpanded, setIsExpanded] = useState(true);
  const [isChildExpanded, setIsChildExpanded] = useState(true);

  const renderSection = () => {
    switch (activeSection) {
      case 'typedaction':
        return <RenderTypeDactionIT isExpanded={isChildExpanded} />;
      case 'incident':
        return <RenderIncidentIT isExpanded={isChildExpanded} />;
      default:
        return <RenderIncidentIT isExpanded={isChildExpanded} />;
    }
  };

  const buttonStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '7px',
    padding: '15px 30px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleChildExpand = () => {
    setIsChildExpanded(!isChildExpanded);
  };

  return (
    <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
      <Header title={t('IncidentIT.Title')} subtitle={t('IncidentIT.Subtitle')} />
      {isExpanded && (
        <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '14px', marginTop: '35px' }}>
          <Button sx={buttonStyle} onClick={() => setActiveSection('typedaction')}>
            {t('IncidentIT.TypeActionButton')}
          </Button>
          <Button sx={buttonStyle} onClick={() => setActiveSection('incident')}>
            {t('IncidentIT.IncidentButton')}
          </Button>
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '14px', marginTop: '20px' }}>
        <IconButton onClick={handleToggleExpand}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        {activeSection === 'incident' && (
          <IconButton onClick={handleToggleChildExpand}>
            {isChildExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </Box>
      {renderSection()}
    </Box>
  );
};

export default IncidentIT;
