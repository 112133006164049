import React, { useState, useEffect } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useGetLastTicketMutation } from "state/api";
import { useSelector } from "react-redux";
import { keyframes } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const IncidentSGAcceuil = () => {
  const { t } = useTranslation();
  const [incidents, setIncidents] = useState([]);
  const [currentIncidentIndex, setCurrentIncidentIndex] = useState(0);
  const [animationState, setAnimationState] = useState("in");
  const [loading, setLoading] = useState(true);
  const [getLastTicket] = useGetLastTicketMutation();
  const userSession = useSelector((state) => state.adminAuth);
  const navigate = useNavigate();

  const fetchIncidents = async () => {
    setLoading(true);
    try {
      const response = await getLastTicket({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      setIncidents(response.incidents.filter(incident => incident.ID_Incident_SG));
      setLoading(false);
    } catch (error) {
      console.error(t('fetch_error_incidents_sg'), error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIncidents();
  }, [getLastTicket]);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationState("out");

      setTimeout(() => {
        setCurrentIncidentIndex((prevIndex) => (prevIndex + 1) % incidents.length);
        setAnimationState("in");
      }, 500);
    }, 30000);

    return () => clearInterval(interval);
  }, [incidents]);

  const getIncidentName = (incident) => {
    if (incident.Type_de_ticket === "equipement") {
      return incident.specificDetails?.Nom_Equipement || t('unknown_details');
    } else if (incident.Type_de_ticket === "equipement_particulier") {
      return incident.specificDetails?.Nom_Ep || t('unknown_details');
    } else if (incident.Type_de_ticket === "service") {
      return incident.specificDetails?.Titre || t('unknown_details');
    }
    return t('unknown_details');
  };

  const getTypeName = (incident) => {
    if (incident.Type_de_ticket === "equipement") {
      return t('equipment_Title');
    } else if (incident.Type_de_ticket === "equipement_particulier") {
      return t('particular_equipment_Title');
    } else if (incident.Type_de_ticket === "service") {
      return t('service');
    }
    return t('unknown');
  };

  const getTimeDifferenceInFrench = (creationDate) => {
    const now = new Date();
    const creation = new Date(creationDate);
    const differenceInMs = now - creation;
    const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
    return t('created_x_minutes_ago', { minutes: differenceInMinutes });
  };

  if (loading) {
    return (
      <Box
        bgcolor="primary.main"
        color="white"
        p={2}
        borderRadius={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (incidents.length === 0) {
    return (
      <Box
        bgcolor="primary.main"
        color="white"
        p={2}
        borderRadius={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <Typography variant="h6">{t('no_new_sg_incidents')}</Typography>
      </Box>
    );
  }

  return (
    <Box
      bgcolor="primary.main"
      color="white"
      p={2}
      borderRadius={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      position="relative"
      width="100%"
      height="100%"
      overflow="hidden"
    >
      <Typography variant="h6" fontWeight="bold" sx={{ textAlign: 'center', marginBottom: '20px' }}>
        {t('new_sg_incident')}
      </Typography>
      <Box
        sx={{
          animation: `${animationState === "in" ? slideIn : slideOut} 0.5s ease-in-out`,
          width: "100%",
          textAlign: 'center',
        }}
      >
        <Box display="flex" justifyContent="center">
          <Box>
            <table style={{ width: "100%", borderCollapse: "collapse", margin: "0 auto" }}>
              <tbody>
                <tr>
                  <td style={{ padding: "5px 10px 5px 0", textAlign: "right", borderRight: "1px solid white" }}>🔧</td>
                  <td style={{ padding: "5px 0 5px 10px", textAlign: "left" }}>{getTypeName(incidents[currentIncidentIndex])}</td>
                </tr>
                <tr>
                  <td style={{ padding: "5px 10px 5px 0", textAlign: "right", borderRight: "1px solid white" }}>🛠️</td>
                  <td style={{ padding: "5px 0 5px 10px", textAlign: "left" }}>{getIncidentName(incidents[currentIncidentIndex])}</td>
                </tr>
                {incidents[currentIncidentIndex].Type_de_ticket !== "service" && incidents[currentIncidentIndex].ID_Portail && (
                  <tr>
                    <td style={{ padding: "5px 10px 5px 0", textAlign: "right", borderRight: "1px solid white" }}>📍</td>
                    <td style={{ padding: "5px 0 5px 10px", textAlign: "left" }}>{incidents[currentIncidentIndex].locationDetails?.Nom_Element || incidents[currentIncidentIndex].locationDetails?.Nom_Etablissement || t('unknown')}</td>
                  </tr>
                )}
                <tr>
                  <td style={{ padding: "5px 10px 5px 0", textAlign: "right", borderRight: "1px solid white" }}>🗓️</td>
                  <td style={{ padding: "5px 0 5px 10px", textAlign: "left" }}>{getTimeDifferenceInFrench(incidents[currentIncidentIndex].Creation)}</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginTop: "10px", alignSelf: 'center' }}
        onClick={() => navigate('/incident_sg')}
      >
        {t('view_all_sg_tickets')}
      </Button>
    </Box>
  );
};

export default IncidentSGAcceuil;
