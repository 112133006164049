import React, { useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import Header from "components/Header";
import RenderEquipementIT from "components/informatique/RenderEquipementIT";
import RenderSignalementIT from "components/informatique/RenderSignalementIT";
import RenderCategorieSignalementIT from "components/informatique/RenderCategorieIT";
import RenderParticulierIT from "components/informatique/RenderParticulierIT";
import { useTranslation } from "react-i18next";

const SignalementsSG = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('categories');

  const renderSection = () => {
    switch (activeSection) {
      case 'categories':
        return <RenderCategorieSignalementIT />;
      case 'equipements':
        return <RenderEquipementIT />;
      case 'signalements':
        return <RenderSignalementIT />;
      case 'particuliers':
        return <RenderParticulierIT />;
      default:
        return <RenderSignalementIT />;
    }
  };

  const buttonStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '10px',
    padding: '15px 30px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('SignalementsSG.title')} subtitle={t('SignalementsSG.subtitle')} />

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '50px' }}>
        <Button sx={buttonStyle} onClick={() => setActiveSection('categories')}>
          {t('SignalementsSG.buttonCategories')}
        </Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('equipements')}>
          {t('SignalementsSG.buttonEquipements')}
        </Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('signalements')}>
          {t('SignalementsSG.buttonSignalements')}
        </Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('particuliers')}>
          {t('SignalementsSG.buttonParticuliers')}
        </Button>
      </Box>

      {renderSection()}
    </Box>
  );
};

export default SignalementsSG;
