import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useInscriptionMutation } from 'state/api';
import './inscription.css';

function Inscription() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pseudo, setPseudo] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [name, setName] = useState('');
    const [prenom, setPrenom] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [authError, setAuthError] = useState(null);
    const [passwordError, setPasswordError] = useState('');
  
    const [inscription, { isLoading }] = useInscriptionMutation();
  
    const validatePassword = (password) => {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return regex.test(password);
    };
  
    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    };
  
    const validatePhoneNumber = (number) => {
        const regex = /^\+?([0-9]{1,3})?[-. ]?(\d{1,3}[-. ]?)*\d{1,4}$/;
        return regex.test(number);
    };
      
    const isFormValid = () => {
        return name && prenom && pseudo && email && password && confirmPassword && phoneNumber &&
               password === confirmPassword &&
               !passwordError && !emailError && !phoneError && !authError;
      };

    const handlePasswordChange = (e) => {
      const newPassword = e.target.value;
      setPassword(newPassword);
      if (!validatePassword(newPassword)) {
        setPasswordError(t('Inscription.passwordError'));
      } else {
        setPasswordError('');
      }
    };
  
    const handleEmailChange = (e) => {
      const newEmail = e.target.value;
      setEmail(newEmail);
      if (!validateEmail(newEmail)) {
        setEmailError(t('Inscription.emailError'));
      } else {
        setEmailError('');
      }
    };
  
    const handlePhoneNumberChange = (e) => {
      const newPhone = e.target.value;
      setPhoneNumber(newPhone);
      if (!validatePhoneNumber(newPhone)) {
        setPhoneError(t('Inscription.phoneError'));
      } else {
        setPhoneError('');
      }
    };
  
    const handleSignup = async (event) => {
      event.preventDefault();
      if (!isFormValid()) {
        setAuthError(t('Inscription.authError'));
        return;
      }
  
      if (password !== confirmPassword) {
        setAuthError(t('Inscription.passwordMismatch'));
        return;
      }
  
      if (!validatePassword(password) || !validateEmail(email) || !validatePhoneNumber(phoneNumber)) {
        setAuthError(t('Inscription.correctErrors'));
        return;
      }
  
      try {
          const userData = { pseudo, password, email, name, prenom, phoneNumber };
          await inscription(userData).unwrap();
          navigate('/acceuilpublic');
      } catch (error) {
          setAuthError(error.data ? error.data.message : t('Inscription.signupError'));
      }
    };

  return (
    <div className="signup-container">
      <form onSubmit={handleSignup} className="signup-form">
        {authError && <p className="signup-error">{authError}</p>}
        {passwordError && <p className="signup-error">{passwordError}</p>}
        {emailError && <p className="signup-error">{emailError}</p>}
        {phoneError && <p className="signup-error">{phoneError}</p>}
        <div className="signup-header">
          <h2>{t('Inscription.signup')}</h2>
          <p>{t('Inscription.fillFields')}</p>
        </div>
        
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t('Inscription.namePlaceholder')}
          className="signup-input"
          id="name"
        />
        <input
          type="text"
          value={prenom}
          onChange={(e) => setPrenom(e.target.value)}
          placeholder={t('Inscription.prenomPlaceholder')}
          className="signup-input"
          id="prenom"
        />
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder={t('Inscription.emailPlaceholder')}
          className="signup-input"
          id="email"
        />
        <input
          type="text"
          value={pseudo}
          onChange={(e) => setPseudo(e.target.value)}
          placeholder={t('Inscription.pseudoPlaceholder')}
          className="signup-input"
          id="pseudo"
        />
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder={t('Inscription.passwordPlaceholder')}
          className="signup-input"
          id="password"
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder={t('Inscription.confirmPasswordPlaceholder')}
          className="signup-input"
          id="confirmPassword"
        />
        <input
          type="text"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder={t('Inscription.phonePlaceholder')}
          className="signup-input"
          id="phoneNumber"
        />
        <button type="submit" className="signup-button" disabled={!isFormValid()}>{t('Inscription.signupButton')}</button>
      </form>
    </div>
  );
}

export default Inscription;
