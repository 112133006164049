import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, List, ListItem, ListItemText, IconButton, TablePagination, Alert } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetAllInfoPortailPartenaireMutation } from 'state/api';
import { useTranslation } from 'react-i18next';

const RenderPortailPartenaires = () => {
  const { t } = useTranslation();
  const [portails, setPortails] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [getAllPortails] = useGetAllInfoPortailPartenaireMutation();
  const userSession = useSelector((state) => state.adminAuth);
  const navigate = useNavigate();

  const fetchPortails = async () => {
    try {
      const response = await getAllPortails({ ID_Etablissement: userSession.ID_Etablissement });
      setPortails(response.data?.filter(portail => portail.Is_Valid) || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des portails:", error);
    }
  };

  useEffect(() => {
    fetchPortails();
  }, [getAllPortails]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredPortails = portails.filter(portail => 
    portail.etablissementSource.Nom_Etablissement.toLowerCase().includes(search.toLowerCase()) || 
    portail.etablissementSource.Mail_Etablissement.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box m="1.5rem 2.5rem">
      <Typography variant="h3" gutterBottom style={{ marginTop: '60px', marginBottom: '20px' }}>
        {t('RenderPortailPartenaires.title')}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField
          label={t('RenderPortailPartenaires.searchLabel')}
          value={search}
          onChange={handleSearchChange}
          style={{ marginRight: '20px' }}
        />
      </Box>
      {filteredPortails.length > 0 ? (
        <List style={{ marginTop: '20px' }}>
          {filteredPortails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((portail) => (
            <ListItem key={portail.ID_Portail} secondaryAction={
              <IconButton
                edge="end"
                aria-label={t('RenderPortailPartenaires.openLabel')}
                onClick={() => navigate(`/portail_client/${portail.ID_Portail}`)}
              >
                <OpenInNewIcon />
              </IconButton>
            }>
              <ListItemText
                primary={`Portail vers ${portail.etablissementSource.Nom_Etablissement}`}
                secondary={`${t('RenderPortailPartenaires.emailLabel')}: ${portail.etablissementSource.Mail_Etablissement}`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Alert severity="info">{t('RenderPortailPartenaires.noResults')}</Alert>
      )}
      <TablePagination
        component="div"
        count={filteredPortails.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Box>
  );
};

export default RenderPortailPartenaires;
