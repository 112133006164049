import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Typography, useScrollTrigger, CssBaseline, Link, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'; // Import useSelector pour accéder à la session utilisateur
import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';

// Fonction pour gérer l'effet de scroll
function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

// Boutons personnalisés pour Connexion et Inscription
const CustomButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#001F54', // Bleu foncé pour le fond
  fontFamily: 'Montserrat, sans-serif',
  margin: theme.spacing(1),
  '&:hover': {
    backgroundColor: '#00123D', // Couleur plus foncée au hover
    transform: 'scale(1.05)',
    transition: 'background-color 0.3s, transform 0.3s',
  },
}));

// Liens pour la navigation à gauche sans soulignement au hover
const NavLink = styled(Link)(({ theme }) => ({
  color: '#001F54', // Bleu foncé pour le texte
  fontFamily: 'Montserrat, sans-serif',
  margin: theme.spacing(2),
  textDecoration: 'none',
  fontSize: '1rem',
  '&:hover': {
    textDecoration: 'none', // Pas de soulignement au hover
  },
}));

export default function Navbar(props) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { isAuthenticated, user } = useSelector((state) => state.userAuth); // Récupère l'état de la session

  const handleLanguageMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = (lang) => {
    setAnchorEl(null);
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };

  // On vérifie que l'utilisateur est défini avant d'accéder aux propriétés
  const userFirstName = user ? user.prenom : '';
  const userLastName = user ? user.nom : '';

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="sticky" style={{ background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(10px)' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Container de gauche avec le titre HURFLYM et les navigations */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Titre HURFLYM cliquable redirigeant vers la page d'accueil */}
              <Link href="/" underline="none">
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: '#000',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 'bold',
                    letterSpacing: '0.3rem', // Espacement entre les lettres
                    textTransform: 'uppercase',
                    cursor: 'pointer', // Ajout d'un curseur pour indiquer un lien
                  }}
                >
                  HURFLYM
                </Typography>
              </Link>

              {/* Navigation pour Abonnements et Documentation */}
              <NavLink href="/abonnements">{t('Navbar.Abonnements')}</NavLink>
              <NavLink href="/documentation">{t('Navbar.Documentation')}</NavLink>
              <NavLink href="/privacy">{t('Navbar.Privacy')}</NavLink>
            </Box>

            {/* Container de droite avec les boutons */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Affichage des boutons en fonction de l'authentification */}
              {isAuthenticated ? (
                <>
                  {/* Bouton Maison avec icône Home */}
                  <Button href="/listeetablissement" startIcon={<HomeIcon />} sx={{ marginRight: 2 }}>
                    {userFirstName} {userLastName}
                  </Button>
                </>
              ) : (
                <>
                  <CustomButton href="/loginuser">{t('Navbar.Login')}</CustomButton>
                  <CustomButton href="/inscription">{t('Navbar.Signup')}</CustomButton>
                </>
              )}

              {/* Bouton de sélection de langue */}
              <IconButton onClick={handleLanguageMenuClick} sx={{ color: '#001F54' }}>
                <LanguageIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleLanguageMenuClose(null)}
              >
                <MenuItem onClick={() => handleLanguageMenuClose('fr')}>Français</MenuItem>
                <MenuItem onClick={() => handleLanguageMenuClose('en')}>English</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
}
