import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import Navbar from '../../components_public/navbar'; // Import de la Navbar

const PrivacyPolitic = () => {
  return (
    <>
      {/* Intégration de la Navbar en haut */}
      <Navbar />

      {/* Conteneur pour le texte de la politique de confidentialité avec marginBottom */}
      <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 8 }}>
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ marginBottom: 2 }}>
            Last updated: September 15, 2024
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 3 }}>
            Interpretation and Definitions
          </Typography>

          <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Interpretation
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
          </Typography>

          <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Definitions
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            For the purposes of this Privacy Policy:
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Hurflym.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Country</strong> refers to: France
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Service</strong> refers to the Website.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Website</strong> refers to Hurflym, accessible from hurflym.com
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 3 }}>
            Collecting and Using Your Personal Data
          </Typography>

          <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Types of Data Collected
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            <strong>Personal Data</strong>
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
          </Typography>

          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - Email address
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - First name and last name
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - Phone number
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - Address, State, Province, ZIP/Postal code, City
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            - Usage Data
          </Typography>

          <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Usage Data
          </Typography>
          <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
            Usage Data is collected automatically when using the Service. It may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
          </Typography>

          {/* Add more sections as necessary following this structure */}
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolitic;
