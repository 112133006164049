import React, { useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import Header from "components/Header";
import RenderCategorieServiceSG from "components/service_generaux/RenderCategorieServiceSG";
import RenderServiceSG from "components/service_generaux/RenderServiceSG";
import { useTranslation } from "react-i18next";

const ServiceSG = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('categories');

  const renderSection = () => {
    switch (activeSection) {
      case 'categorieservice':
        return <RenderCategorieServiceSG />;
      case 'service':
        return <RenderServiceSG />;
      default:
        return <RenderServiceSG />;
    }
  };

  const buttonStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '10px',
    padding: '15px 30px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('ServiceSG.title')} subtitle={t('ServiceSG.subtitle')} />

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '50px' }}>
        <Button sx={buttonStyle} onClick={() => setActiveSection('categorieservice')}>
          {t('ServiceSG.buttonCategory')}
        </Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('service')}>
          {t('ServiceSG.buttonService')}
        </Button>
      </Box>

      {renderSection()}

    </Box>
  );
};

export default ServiceSG;
