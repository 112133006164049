import React, { useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import Header from "components/Header";
import RenderFeedback from "components/Feedback";
import RenderSection from "components/Section";
import FeedbackData from "components/FeedbackData";
import { useTranslation } from "react-i18next";

const FeedbackApp = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('categories');

  const renderSection = () => {
    switch (activeSection) {
      case 'section':
        return <RenderSection />;
      case 'feedback':
        return <RenderFeedback />;
      case 'feedbackdata':
        return <FeedbackData />;
      default:
        return <RenderSection />;
    }
  };

  const buttonStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '10px',
    padding: '15px 30px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('FeedbackApp.Title')} subtitle={t('FeedbackApp.Subtitle')} />

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '50px' }}>
        <Button sx={buttonStyle} onClick={() => setActiveSection('section')}>
          {t('FeedbackApp.Section')}
        </Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('feedback')}>
          {t('FeedbackApp.Feedback')}
        </Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('feedbackdata')}>
          {t('FeedbackApp.Statistics')}
        </Button>
      </Box>

      {renderSection()}
    </Box>
  );
};

export default FeedbackApp;
