import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Container, Snackbar } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Import de useNavigate
import { useSelector } from 'react-redux'; // Import pour accéder à l'état de l'utilisateur
import Navbar from '../../components_public/navbar.jsx'; // Import de la Navbar

const Abonnements = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialisation de useNavigate
  const userAuth = useSelector((state) => state.userAuth); // Sélection de l'état utilisateur
  const [openSnackbar, setOpenSnackbar] = React.useState(false); // Gestion de l'affichage du Snackbar

  // Liste des avantages pour le tableau
  const abonnementsData = [
    {
      avantage: t('Abonnement.Double domaine informatique et service généraux présent dans l\'application'),
      proprietaire: true,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Signalement par QrCode disponible pour les collaborateurs ainsi que web'),
      proprietaire: true,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Signalement pour un équipement ou un équipement particulier disponible'),
      proprietaire: true,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Des demandes de services peuvent être effectuées auprès des responsables de l\'établissement dans l\'application'),
      proprietaire: true,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Gestion des lieux et des espaces pour un découpage qui correspond au client'),
      proprietaire: true,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Personnalisation de la page des signalements à l\'image de marque du client avec son logo'),
      proprietaire: true,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Gestion des administrateurs et des droits confiés aux personnes responsables'),
      proprietaire: true,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Feedback et analyse disponible'),
      proprietaire: false,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Accès aux statistiques'),
      proprietaire: false,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Création d\'un portail pour travailler avec des entreprises partenaires'),
      proprietaire: false,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.Possibilité d\'intégrer un portail d\'une entreprise partenaire'),
      proprietaire: false,
      entreprise: true,
      company: true,
    },
    {
      avantage: t('Abonnement.API pour les développeurs pour créer des outils avec l\'écosystème'),
      proprietaire: false,
      entreprise: false,
      company: true,
    },
    {
      avantage: t('Abonnement.Accès aux prochaines features (chatbotIA, paiements intégré)'),
      proprietaire: false,
      entreprise: false,
      company: true,
    },
  ];

  // Fonction pour afficher une icône verte ou rien
  const renderIcon = (value) => {
    return value ? (
      <CheckCircle sx={{ color: 'green' }} />
    ) : (
      '' // Laisser vide s'il n'y a pas d'avantage
    );
  };

  // Style pour effet de lumière métallique sans animation d'hover
  const metallicEffect = (color1, color2) => ({
    background: `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  });

  // Animation pour les boutons "S'inscrire" uniquement
  const buttonHover = {
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'transform 0.3s',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
  };

  // Fonction pour rediriger vers la page d'inscription si l'utilisateur n'est pas connecté
  const handleSubscription = () => {
    navigate(`/inscription`);
  };

  return (
    <Box>
      {/* Nouvelle Box contenant la Navbar et la Box de contenu */}
      <Navbar /> {/* Navbar au-dessus */}

      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: '#001F54',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          width: '100%',
          pt: '75px', // Ajout du margin top
          pb: '75px', // Ajout du margin bottom
        }}
      >
        {/* Animation du fond étoilé */}
        {[...Array(60)].map((_, i) => (
          <Box
            key={i}
            sx={{
              position: 'absolute',
              width: '3px', // Augmentation de la taille des étoiles
              height: '3px',
              backgroundColor: 'white',
              borderRadius: '50%',
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `twinkle ${Math.random() * 5 + 3}s infinite`,
              opacity: Math.random() * 0.8 + 0.2, // Plus brillant
              filter: 'blur(1px)',
            }}
          />
        ))}

        {/* Titre principal */}
        <Typography
          variant="h2"
          sx={{
            color: 'white',
            mb: 4,
            fontWeight: 'bold',
            letterSpacing: '0.2rem',
          }}
        >
          {t('Abonnement.Nos abonnements')}
        </Typography>

        {/* Tableau comparatif des avantages */}
        <Container sx={{ zIndex: 1 }}>
          <TableContainer component={Paper} sx={{ backgroundColor: '#001F54', color: 'white' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '20%', color: 'white' }} /> {/* Colonne de gauche */}
                  {/* En-têtes des abonnements */}
                  <TableCell
                    align="center"
                    sx={metallicEffect('#FF6347', '#FF4500')} // Rouge pour Propriétaire
                  >
                    <Typography variant="h4" sx={{ mb: 3 }}>{t('Abonnement.Propriétaire')}</Typography> {/* Taille augmentée */}
                    <Typography variant="body1" sx={{ mb: 4 }}>{t('Abonnement.Accès basique')}</Typography> {/* Plus d'espace ajouté */}
                    <Typography variant="body2" sx={{ color: 'yellow', fontWeight: 'bold', mb: 2 }}>{t('Abonnement.3 premiers mois gratuits')}</Typography>
                    <Typography variant="h3" sx={{ mt: 1, mb: 2 }}>146€</Typography> {/* Taille augmentée et marges */}
                    <Typography variant="body2" sx={{ color: 'gray' }}>/mois</Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 2, ...buttonHover }} // Animation sur hover pour le bouton
                      onClick={handleSubscription} // Redirection vers la page d'inscription
                    >
                      {t('Abonnement.Souscrire')}
                    </Button>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={metallicEffect('#4682B4', '#1E90FF')} // Bleu pour Entreprise
                  >
                    <Typography variant="h4" sx={{ mb: 3 }}>{t('Abonnement.Entreprise')}</Typography> {/* Taille augmentée */}
                    <Typography variant="body1" sx={{ mb: 4 }}>{t('Abonnement.Accès intermédiaire')}</Typography> {/* Plus d'espace ajouté */}
                    <Typography variant="body2" sx={{ color: 'yellow', fontWeight: 'bold', mb: 2 }}>{t('Abonnement.3 premiers mois gratuits')}</Typography>
                    <Typography variant="h3" sx={{ mt: 1, mb: 2 }}>243€</Typography> {/* Taille augmentée et marges */}
                    <Typography variant="body2" sx={{ color: 'gray' }}>/mois</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2, ...buttonHover }} // Animation sur hover pour le bouton
                      onClick={handleSubscription} // Redirection vers la page d'inscription
                    >
                      {t('Abonnement.Souscrire')}
                    </Button>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={metallicEffect('#32CD32', '#228B22')} // Vert pour Company
                  >
                    <Typography variant="h4" sx={{ mb: 3 }}>{t('Abonnement.Company')}</Typography> {/* Taille augmentée */}
                    <Typography variant="body1" sx={{ mb: 4 }}>{t('Abonnement.Accès complet et publicitaire')}</Typography> {/* Plus d'espace ajouté */}
                    <Typography variant="body2" sx={{ color: 'yellow', fontWeight: 'bold', mb: 2 }}>{t('Abonnement.3 premiers mois gratuits')}</Typography>
                    <Typography variant="h3" sx={{ mt: 1, mb: 2 }}>297€</Typography> {/* Taille augmentée et marges */}
                    <Typography variant="body2" sx={{ color: 'gray' }}>/mois</Typography>
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{ mt: 2, ...buttonHover }} // Animation sur hover pour le bouton
                      onClick={handleSubscription} // Redirection vers la page d'inscription
                    >
                      {t('Abonnement.Souscrire')}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {abonnementsData.map((row) => (
                  <TableRow key={row.avantage}>
                    {/* Colonne des avantages */}
                    <TableCell component="th" scope="row" sx={{ color: 'white', fontWeight: 'bold' }}>
                      {row.avantage}
                    </TableCell>
                    {/* Colonnes des abonnements avec icônes */}
                    <TableCell align="center" sx={{ color: 'white', width: '26%' }}>
                      {renderIcon(row.proprietaire)}
                    </TableCell>
                    <TableCell align="center" sx={{ color: 'white', width: '26%' }}>
                      {renderIcon(row.entreprise)}
                    </TableCell>
                    <TableCell align="center" sx={{ color: 'white', width: '26%' }}>
                      {renderIcon(row.company)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>

      {/* Snackbar pour avertir l'utilisateur qu'il doit être connecté */}
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={t('Vous devez être connecté pour souscrire à un abonnement.')}
        autoHideDuration={6000}
      />
    </Box>
  );
};

export default Abonnements;
