import React from 'react';
import { AppBar, Toolbar, Button, Typography, useScrollTrigger, CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useLogoutMutation } from 'state/api';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'; // Importer useDispatch
import { userLogout } from 'state/userAuth'; // Importer l'action userLogout

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  background: 'transparent',
  padding: '0 20px',
});

const TitleTypography = styled(Typography)({
  flexGrow: 0,
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  color: 'white',
});

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: { background: trigger ? 'rgba(255, 255, 255, 0.8)' : 'transparent', boxShadow: trigger ? 'rgba(0, 0, 0, 0.25) 0px 4px 12px' : 'none' },
  });
}

export default function NavbarUtilisateur() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialiser useDispatch
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      dispatch(userLogout()); // Appeler l'action pour réinitialiser l'état de l'authentification
      navigate('/acceuilpublic');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  return (
    <>
      <CssBaseline />
      <ElevationScroll>
        <AppBar position="sticky">
          <StyledToolbar>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TitleTypography variant="h6" component="div">
                {t('NavbarUtilisateur.Title')}
              </TitleTypography>
              <div style={{ marginLeft: '50px' }}>
                <Button color="inherit" onClick={() => navigate('/infoutilisateur')}>
                  {t('NavbarUtilisateur.PersonalInfo')}
                </Button>
                <Button color="inherit" onClick={() => navigate('/listeetablissement')}>
                  {t('NavbarUtilisateur.Establishments')}
                </Button>
                <Button color="inherit" onClick={() => navigate('/abonnement')}>
                  {t('NavbarUtilisateur.ServicesBilling')}
                </Button>
              </div>
            </div>
            <Button color="inherit" onClick={handleLogout}>
              {t('NavbarUtilisateur.Logout')}
            </Button>
          </StyledToolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
}
