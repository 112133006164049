import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { useGetAllStatEtablissementMutation } from "state/api";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const IncidentGraph = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [graphDataITSG, setGraphDataITSG] = useState([]);
  const [graphDataEquipement, setGraphDataEquipement] = useState([]);
  const [graphDataPortail, setGraphDataPortail] = useState([]);
  const [graphDataLieu, setGraphDataLieu] = useState([]);

  const userSession = useSelector((state) => state.adminAuth);
  const [getAllStatEtablissement] = useGetAllStatEtablissementMutation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAllStatEtablissement({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      setData(response.incidents);
      prepareGraphData(response.incidents);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };

  const prepareGraphData = (incidents) => {
    const countIT = incidents.filter(incident => incident.ID_Incident_IT).length;
    const countSG = incidents.length - countIT;
    setGraphDataITSG([
      { type: "Informatique", count: countIT },
      { type: "Service Généraux", count: countSG }
    ]);

    const countEquipement = incidents.filter(incident => incident.ID_Equipement).length;
    const countEquipementParticulier = incidents.filter(incident => incident.ID_Ep).length;
    const countService = incidents.filter(incident => incident.ID_Service).length;
    setGraphDataEquipement([
      { id: "Équipement", data: countEquipement },
      { id: "Équipement Particulier", data: countEquipementParticulier },
      { id: "Service", data: countService }
    ]);

    const portailCounts = incidents.reduce((acc, incident) => {
      if (incident.ID_Portail) {
        const etablissement = incident.etablissementDetails?.Nom_Etablissement || "Inconnu";
        acc[etablissement] = (acc[etablissement] || 0) + 1;
      }
      return acc;
    }, {});
    const portailData = Object.keys(portailCounts).map(portail => ({
      id: portail,
      data: portailCounts[portail],
    }));
    setGraphDataPortail(portailData);

    const lieuCounts = incidents.reduce((acc, incident) => {
      if (incident.ID_Noeud) {
        const lieu = incident.locationDetails?.Nom_Element || "Inconnu";
        acc[lieu] = (acc[lieu] || 0) + 1;
      }
      return acc;
    }, {});
    const lieuData = Object.keys(lieuCounts).map(lieu => ({
      id: lieu,
      data: lieuCounts[lieu],
    }));
    setGraphDataLieu(lieuData);
  };

  const textColor = "#FFFFFF";
  const barLabelColor = "#B0B0B0";
  const axisLineColor = "#B0B0B0";

  const commonAxisSettings = {
    tickSize: 5,
    tickPadding: 15,
    tickRotation: 0,
    tickColor: textColor,
    legendTextColor: textColor,
  };

  const customTooltip = (bar) => (
    <div style={{ background: '#222', padding: '5px', color: '#fff', borderRadius: '3px' }}>
      <strong>{bar.indexValue}</strong>: {bar.value} incidents
    </div>
  );

  return (
    <Box sx={{ width: "100%", display: 'flex', flexDirection: 'column', gap: '50px' }}>
      <Typography variant="h4" align="center" sx={{ color: textColor }}>{t('IncidentGraph.title')}</Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
        <Box sx={{ width: "48%", height: "400px" }}>
          <Typography variant="h6" align="center" sx={{ color: textColor, marginBottom: '10px' }}>
            {t('IncidentGraph.itVsSgTitle')}
          </Typography>
          <ResponsiveBar
            data={graphDataITSG}
            keys={['count']}
            indexBy="type"
            margin={{ top: 30, right: 50, bottom: 50, left: 60 }}
            padding={0.3}
            colors={{ scheme: 'nivo' }}
            axisBottom={{
              ...commonAxisSettings,
              tickValues: graphDataITSG.map(item => item.type),
            }}
            axisLeft={{
              ...commonAxisSettings,
              legend: t('IncidentGraph.countLabel'),
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={barLabelColor}
            enableGridX={false}
            enableGridY={true}
            gridYValues={5}
            theme={{
              axis: {
                ticks: {
                  text: { fill: textColor },
                  line: { stroke: axisLineColor },
                },
                legend: {
                  text: { fill: textColor },
                },
              },
              grid: {
                line: { stroke: axisLineColor },
              },
              labels: { text: { fill: barLabelColor } },
            }}
            tooltip={customTooltip}
          />
        </Box>

        <Box sx={{ width: "48%", height: "400px" }}>
          <Typography variant="h6" align="center" sx={{ color: textColor, marginBottom: '10px' }}>
            {t('IncidentGraph.equipementTitle')}
          </Typography>
          <ResponsiveLine
            data={[{
              id: 'Types',
              data: graphDataEquipement.map((item) => ({
                x: item.id,
                y: item.data
              }))
            }]}
            margin={{ top: 30, right: 50, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: true,
              reverse: false,
            }}
            axisBottom={{
              ...commonAxisSettings,
              tickValues: graphDataEquipement.map(item => item.id),
            }}
            axisLeft={{
              ...commonAxisSettings,
              legend: t('IncidentGraph.countLabel'),
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            colors={{ scheme: 'nivo' }}
            pointSize={10}
            pointBorderWidth={2}
            pointLabelYOffset={-12}
            enableGridX={false}
            enableGridY={true}
            gridYValues={5}
            theme={{
              axis: {
                ticks: {
                  text: { fill: textColor },
                  line: { stroke: axisLineColor },
                },
                legend: {
                  text: { fill: textColor },
                },
              },
              grid: {
                line: { stroke: axisLineColor },
              },
              labels: { text: { fill: barLabelColor } },
            }}
            tooltip={(point) => (
              <div style={{ background: '#222', padding: '5px', color: '#fff', borderRadius: '3px' }}>
                <strong>{point.data.xFormatted}</strong>: {point.data.yFormatted} incidents
              </div>
            )}
          />
        </Box>
      </Box>

      <Box sx={{ width: "100%", height: "400px" }}>
        <Typography variant="h6" align="center" sx={{ color: textColor, marginBottom: '10px' }}>
          {t('IncidentGraph.portailTitle')}
        </Typography>
        <ResponsiveLine
          data={[{
            id: 'Portails',
            data: graphDataPortail.map((item) => ({
              x: item.id,
              y: item.data
            }))
          }]}
          margin={{ top: 30, right: 50, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          axisBottom={{
            ...commonAxisSettings,
            tickValues: graphDataPortail.map(item => item.id),
          }}
          axisLeft={{
            ...commonAxisSettings,
            legend: t('IncidentGraph.countLabel'),
            legendPosition: 'middle',
            legendOffset: -50,
          }}
          colors={{ scheme: 'nivo' }}
          pointSize={10}
          pointBorderWidth={2}
          pointLabelYOffset={-12}
          enableGridX={false}
          enableGridY={true}
          gridYValues={5}
          theme={{
            axis: {
              ticks: {
                text: { fill: textColor },
                  line: { stroke: axisLineColor },
              },
              legend: {
                text: { fill: textColor },
              },
            },
            grid: {
              line: { stroke: axisLineColor },
            },
            labels: { text: { fill: barLabelColor } },
          }}
          tooltip={(point) => (
            <div style={{ background: '#222', padding: '5px', color: '#fff', borderRadius: '3px' }}>
              <strong>{point.data.xFormatted}</strong>: {point.data.yFormatted} incidents
            </div>
          )}
        />
      </Box>

      <Box sx={{ width: "100%", height: "400px" }}>
        <Typography variant="h6" align="center" sx={{ color: textColor, marginBottom: '10px' }}>
          {t('IncidentGraph.lieuTitle')}
        </Typography>
        <ResponsiveBar
          data={graphDataLieu}
          keys={['data']}
          indexBy="id"
          margin={{ top: 30, right: 50, bottom: 50, left: 60 }}
          padding={0.3}
          colors={{ scheme: 'nivo' }}
          axisBottom={{
            ...commonAxisSettings,
            tickValues: graphDataLieu.map(item => item.id),
          }}
          axisLeft={{
            ...commonAxisSettings,
            legend: t('IncidentGraph.countLabel'),
            legendPosition: 'middle',
            legendOffset: -50,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={barLabelColor}
          enableGridX={false}
          enableGridY={true}
          gridYValues={5}
          theme={{
            axis: {
              ticks: {
                text: { fill: textColor },
                line: { stroke: axisLineColor },
              },
              legend: {
                text: { fill: textColor },
              },
            },
            grid: {
              line: { stroke: axisLineColor },
            },
            labels: { text: { fill: barLabelColor } },
          }}
          tooltip={customTooltip}
        />
      </Box>
    </Box>
  );
};

export default IncidentGraph;
