import React, { useState, useEffect } from "react";
import { Box, useTheme, Button } from "@mui/material";
import Header from "components/Header";
import RenderKeyMetrics from "./RenderKeyMetrics";
import IncidentGraph from "./IncidentGraph";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useGetAllStatEtablissementMutation, useGetEssentialStatEtablissementMutation } from "state/api";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Statistique = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [data, setData] = useState(null);
  const [essentialData, setEssentialData] = useState(null);
  
  const [getAllStatEtablissement] = useGetAllStatEtablissementMutation();
  const [getEssentialStatEtablissement] = useGetEssentialStatEtablissementMutation();

  const userSession = useSelector((state) => state.adminAuth);

  const fetchData = async () => {
    try {
      const response = await getAllStatEtablissement({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      setData(response.incidents);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };

  const fetchEssentialData = async () => {
    try {
      const response = await getEssentialStatEtablissement({ ID_Etablissement: userSession.ID_Etablissement }).unwrap();
      setEssentialData(response);
    } catch (error) {
      console.error("Erreur lors de la récupération des données essentielles :", error);
    }
  };

  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(data)], { type: "application/json" });
    element.href = URL.createObjectURL(file);
    element.download = "incident_data.json";
    document.body.appendChild(element); // Requis pour Firefox
    element.click();
  };

  useEffect(() => {
    fetchData();
    fetchEssentialData();
  }, []);

  return (
    <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
      <Header title={t('Statistique.headerTitle')} subtitle={t('Statistique.headerSubtitle')} />
      
      {data && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "14px", marginTop: "20px" }}>
          <Button
            variant="outlined"
            sx={{
              marginRight: "10px",
              padding: "6px 12px",
              fontSize: "14px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.background.default,
              },
            }}
            startIcon={<RefreshIcon />}
            onClick={() => {
              fetchData();
              fetchEssentialData();
            }}
          >
            {t('Statistique.reloadButton')}
          </Button>
          <Button
            variant="outlined"
            sx={{
              padding: "6px 12px",
              fontSize: "14px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.background.default,
              },
            }}
            startIcon={<DownloadIcon />}
            onClick={handleDownload}
            disabled={!data}
          >
            {t('Statistique.downloadButton')}
          </Button>
        </Box>
      )}

      {data && essentialData && (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "14px" }}>
          <RenderKeyMetrics data={essentialData} />
          <IncidentGraph data={data} />
        </Box>
      )}
    </Box>
  );
};

export default Statistique;
