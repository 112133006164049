import React, { useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import Header from "components/Header";
import RenderCategorieServiceIT from "components/informatique/RenderCategorieServiceIT";
import RenderServiceIT from "components/informatique/RenderServiceIT";
import { useTranslation } from "react-i18next";

const ServiceIT = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('categories');

  const renderSection = () => {
    switch (activeSection) {
      case 'categorieservice':
        return <RenderCategorieServiceIT />;
      case 'service':
        return <RenderServiceIT />;
      default:
        return <RenderServiceIT />;
    }
  };

  const buttonStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '10px',
    padding: '15px 30px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('ServiceIT.title')} subtitle={t('ServiceIT.subtitle')} />

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '50px' }}>
        <Button sx={buttonStyle} onClick={() => setActiveSection('categorieservice')}>
          {t('ServiceIT.buttonCategory')}
        </Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('service')}>
          {t('ServiceIT.buttonService')}
        </Button>
      </Box>

      {renderSection()}

    </Box>
  );
};

export default ServiceIT;
