import React, { useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import Header from "components/Header";
import General from "./General";
import CompteMachine from "./CompteMachine";
import Webhook from "./Webhook";
import { useTranslation } from "react-i18next";

const Developer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState('general');

  const renderSection = () => {
    switch (activeSection) {
      case 'general':
        return <General />;
      case 'compteMachines':
        return <CompteMachine />;
      case 'webhook':
        return <Webhook />;
      default:
        return <General />;
    }
  };

  const buttonStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '10px',
    padding: '15px 30px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('Developer.Title')} subtitle={t('Developer.Subtitle')} />

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '50px' }}>
        <Button sx={buttonStyle} onClick={() => setActiveSection('general')}>
          {t('Developer.General')}
        </Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('compteMachines')}>
          {t('Developer.CompteMachines')}
        </Button>
        <Button sx={buttonStyle} onClick={() => setActiveSection('webhook')}>
          {t('Developer.Webhook')}
        </Button>
      </Box>

      {renderSection()}
    </Box>
  );
};

export default Developer;
