import React, { useState, useEffect } from "react";
import { Box, useTheme, Grid, TextField, Button } from "@mui/material";
import { useUploadImageMutation, useRecuperationImageQuery, useGetPersonnalisationQuery, useSetPersonnalisationMutation } from "state/api";
import Header from "components/Header";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import './style.css'; 

// Utiliser la variable d'environnement REACT_APP_BASE_URL pour définir la base de l'URL
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5001';


const Personnalisation = () => {
  const { t } = useTranslation();
  const [uploadImage] = useUploadImageMutation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [uploadFormStatus, setUploadFormStatus] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [message, setMessage] = useState(null);
  const [showBox, setShowBox] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const [couleur_decriture, setCouleur_decriture] = useState("#000000");
  const [couleur_de_fond, setCouleur_de_fond] = useState("#FFFFFF");
  const [couleur_de_case, setCouleur_de_case] = useState("#000000");
  const [couleur_de_case_active, setCouleur_de_case_active] = useState("#636363");
  const [couleur_dombre_de_case, setCouleur_dombre_de_case] = useState("#000000");
  const [couleur_bouton, setCouleur_bouton] = useState("#000000");
  const [couleur_bouton_ecriture, setCouleur_bouton_ecriture] = useState("#000000");
  const [couleur_ombre_bouton, setCouleur_ombre_bouton] = useState("#000000");
  const [police_de_caractere, setPolice_de_caractere] = useState("");
  const [URL_police_de_caractere, setURL_police_de_caractere] = useState("");

  const nom_image = useSelector((state) => state.adminAuth.ID_Etablissement);
  const { data: imageData } = useRecuperationImageQuery(nom_image);
  const { data: personnalisationData } = useGetPersonnalisationQuery(nom_image);
  const [setPersonnalisation] = useSetPersonnalisationMutation();

  useEffect(() => {
    if (!imageData && nom_image) {
      const imageUrl = `${BASE_URL}/images/image/${nom_image}`;
      setSelectedFile(imageUrl);
    }
  }, [nom_image, imageData]);

  useEffect(() => {
    if (personnalisationData) {
      setCouleur_decriture(personnalisationData.couleur_ecriture || "#000000");
      setCouleur_de_fond(personnalisationData.couleur_de_fond || "#FFFFFF");
      setCouleur_de_case(personnalisationData.couleur_de_case || "#000000");
      setCouleur_de_case_active(personnalisationData.couleur_de_case_active || "#636363");
      setCouleur_dombre_de_case(personnalisationData.couleur_dombre_de_case || "#000000");
      setCouleur_bouton(personnalisationData.couleur_bouton || "#000000");
      setCouleur_bouton_ecriture(personnalisationData.couleur_bouton_ecriture || "#000000");
      setCouleur_ombre_bouton(personnalisationData.couleur_de_lombre_du_bouton || "#000000");
      setPolice_de_caractere(personnalisationData.police_de_caractere || "");
      setURL_police_de_caractere(personnalisationData.URL_police_de_caractere || "");
    }
  }, [personnalisationData]);

  const ajouterPolice = () => {
    const link = document.createElement("link");
    link.href = `'${URL_police_de_caractere}'`;
    link.rel = "stylesheet";
    link.type = "text/css";
    document.head.appendChild(link);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setUploadStatus('loading');
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      await uploadImage(formData).unwrap();
      setUploadStatus('success');
    } catch (err) {
      setUploadStatus('error');
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setUploadStatus(null); 
      }, 5000);
    }
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const personnalisationData = {
      couleur_de_fond: couleur_de_fond,
      couleur_de_case: couleur_de_case,
      couleur_de_case_active: couleur_de_case_active,
      couleur_dombre_de_case: couleur_dombre_de_case,
      couleur_ecriture: couleur_decriture,
      couleur_bouton: couleur_bouton,
      couleur_bouton_ecriture: couleur_bouton_ecriture,
      couleur_ombre_bouton: couleur_ombre_bouton,
      police_de_caractere: police_de_caractere,
      URL_police_de_caractere: URL_police_de_caractere,
      ID_Etablissement: nom_image,
    };

    try {
      await setPersonnalisation(personnalisationData).unwrap();
      setUploadFormStatus('success');
      setMessage(t('Personnalisation.successMessage'));
    } catch (err) {
      setUploadFormStatus('error');
      setMessage(t('Personnalisation.errorMessage'));
    } finally {
      setIsSubmittingForm(false);
    }
  };

  useEffect(() => {
    if (uploadFormStatus === 'success' || uploadFormStatus === 'error') {
      setShowBox(true);
      setIsExiting(false);

      const exitTimeout = setTimeout(() => {
        setIsExiting(true);
        const hideTimeout = setTimeout(() => {
          setShowBox(false);
          setIsExiting(false);
          setUploadFormStatus("");
        }, 1000);

        return () => clearTimeout(hideTimeout);
      }, 5000);
      return () => clearTimeout(exitTimeout);
    }
  }, [uploadFormStatus]);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t('Personnalisation.headerTitle')} subtitle={t('Personnalisation.headerSubtitle')} />
      <Grid container spacing={2} className="column-container">
        <Grid item xs={6} className="column" id='simulation'>
          <Box
            marginLeft="25%"
            width="50%"
            height="600px"
            borderRadius="16px" 
            backgroundColor={couleur_de_fond} 
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ fontFamily: `'${police_de_caractere}', sans-serif` }}
          >
            {selectedFile && (
              <img
                src={selectedFile}
                alt="logo"
                style={{ width: '200px', height: '100px' }} 
              />
            )}
            <br></br>
            <br></br>
            <Box
              width="75%" 
              height="40%" 
              borderRadius="10%" 
              backgroundColor={couleur_de_case} 
              boxShadow={`0px 4px 8px ${couleur_dombre_de_case}, 0px 0px 16px ${couleur_dombre_de_case}`}
              marginBottom="16px"
              color={couleur_decriture}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: couleur_bouton,
                boxShadow: `0px 4px 8px ${couleur_ombre_bouton}, 0px 0px 16px ${couleur_ombre_bouton}`,
                color: couleur_bouton_ecriture,
                fontFamily: `'${police_de_caractere}', sans-serif`,
              }}
            >
              {t('Personnalisation.uploadImage')}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={6} className="column">
          <Box m="1.5rem 2.5rem">
            {showBox && (
              <Box
                className={isExiting ? "slide-out" : "slide-in"}
                position="fixed"
                top="10%"
                left="50%"
                transform="translateX(-50%)"
                bgcolor={uploadFormStatus === 'success' ? '#48D1CC' : '#FA8072'}
                color="white"
                p={2}
                zIndex="tooltip"
                borderRadius="10px"
                fontSize="1.2rem"
                fontWeight="bold"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ whiteSpace: 'nowrap' }}
              >
                {uploadFormStatus === 'success' ? <InfoOutlinedIcon /> : '⚠️ '}
                {message}
              </Box>
            )}

            <form onSubmit={handleSubmit}>
              <input type="file" onChange={handleFileChange} />
              <button type="submit" disabled={isSubmitting}>{t('Personnalisation.uploadImage')}</button>
            </form>
            <br></br>
            {uploadStatus === 'loading' && <div>{t('Personnalisation.uploadLoading')}</div>}
            {uploadStatus === 'success' && <div>{t('Personnalisation.uploadSuccess')}</div>}
            {uploadStatus === 'error' && <div>{t('Personnalisation.uploadError')}</div>}

            <form onSubmit={handleSubmitForm}>
              <br></br>
              <TextField
                label={t('Personnalisation.backgroundColorLabel')}
                type="color"
                fullWidth
                value={couleur_de_fond}
                onChange={(e) => setCouleur_de_fond(e.target.value)}
              />
              <br></br>
              <br></br>
              <TextField
                label={t('Personnalisation.caseColorLabel')}
                type="color"
                fullWidth
                value={couleur_de_case} 
                onChange={(e) => setCouleur_de_case(e.target.value)}
              />
              <br></br>
              <br></br>
              <TextField
                label={t('Personnalisation.activeCaseColorLabel')}
                type="color"
                fullWidth
                value={couleur_de_case_active} 
                onChange={(e) => setCouleur_de_case_active(e.target.value)}
              />
              <br></br>
              <br></br>
              <TextField
                label={t('Personnalisation.caseShadowColorLabel')}
                type="color"
                fullWidth
                value={couleur_dombre_de_case} 
                onChange={(e) => setCouleur_dombre_de_case(e.target.value)}
              />
              <br></br>
              <br></br>
              <TextField
                label={t('Personnalisation.textColorLabel')}
                type="color"
                fullWidth
                value={couleur_decriture} 
                onChange={(e) => setCouleur_decriture(e.target.value)}
              />
              <br></br>
              <br></br>
              <TextField
                label={t('Personnalisation.buttonColorLabel')}
                type="color"
                fullWidth
                value={couleur_bouton} 
                onChange={(e) => setCouleur_bouton(e.target.value)}
              />
              <br></br>
              <br></br>
              <TextField
                label={t('Personnalisation.buttonTextColorLabel')}
                type="color"
                fullWidth
                value={couleur_bouton_ecriture} 
                onChange={(e) => setCouleur_bouton_ecriture(e.target.value)}
              />
              <br></br>
              <br></br>
              <TextField
                label={t('Personnalisation.buttonShadowColorLabel')}
                type="color"
                fullWidth
                value={couleur_ombre_bouton} 
                onChange={(e) => setCouleur_ombre_bouton(e.target.value)}
              />
              <br></br>
              <br></br>
              <TextField
                label={t('Personnalisation.fontUrlLabel')}
                type="text"
                fullWidth
                value={URL_police_de_caractere}
                onChange={(e) => {setURL_police_de_caractere(e.target.value); ajouterPolice()}}
              />
              <br></br>
              <br></br>
              <TextField
                label={t('Personnalisation.fontNameLabel')}
                type="text"
                fullWidth
                value={police_de_caractere}
                onChange={(e) => setPolice_de_caractere(e.target.value)}
              />
              <br></br>
              <br></br>
              <Button type="submit" variant="contained" color="primary" disabled={isSubmittingForm}>
                {t('Personnalisation.submitButtonText')}
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Personnalisation;
