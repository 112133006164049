import React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ReceiptLongOutlined,
  TodayOutlined,
  AdminPanelSettingsOutlined,
  EngineeringOutlined,
  ComputerOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import BugReportIcon from '@mui/icons-material/BugReport';
import WorkIcon from '@mui/icons-material/Work';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BusinessIcon from '@mui/icons-material/Business';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import { useLogoutEtablissementMutation } from "state/api";
import { useSelector } from 'react-redux';
import LanguageIcon from '@mui/icons-material/Language';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AppsIcon from '@mui/icons-material/Apps';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PublicIcon from '@mui/icons-material/Public';
import CloudIcon from '@mui/icons-material/Cloud';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MapIcon from '@mui/icons-material/Map';
import PlaceIcon from '@mui/icons-material/Place';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import DomainIcon from '@mui/icons-material/Domain';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { adminLogout } from "../state/adminAuth";
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const roleInfo = useSelector((state) => state.adminAuth.roleInfo);
  const abonnementType = useSelector((state) => state.adminAuth.abonnementType);  // Vérification du type d'abonnement
  const user = useSelector((state) => state.userAuth.user);
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [logout] = useLogoutEtablissementMutation();
  

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      dispatch(adminLogout());
      navigate(`/listeetablissement`);
    } catch (error) {
      console.error("Erreur lors de la déconnexion :", error);
    }
  }

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  // Configuration des éléments du menu avec vérification du type d'abonnement
  const navItems = [
    { text: t('Sidebar.Accueil'), icon: <HomeOutlined />, path: "/acceuil" },
    { text: t('Sidebar.ServiceGeneraux'), icon: null },
    { text: t('Sidebar.SignalementSG'), icon: <FormatListBulletedIcon />, path: "/signalement_sg", visible: roleInfo.Lecture_Signalement_Service_Generaux },
    { text: t('Sidebar.IncidentSG'), icon: <ReceiptLongOutlined />, path: "/incident_sg", visible: roleInfo.Lecture_Incident_Service_Generaux },
    { text: t('Sidebar.ServicesSG'), icon: <EngineeringOutlined />, path: "/service_sg", visible: roleInfo.Lecture_Services_Service_Generaux },
    { text: t('Sidebar.PortailSG'), icon: <AppsIcon />, path: "/portail_sg", visible: roleInfo.Lecture_Secteur && abonnementType !== "proprietaire" },  // Masqué pour "proprietaire"
    { text: t('Sidebar.SecteurSG'), icon: <BusinessIcon />, path: "/secteursg", visible: roleInfo.Lecture_Secteur },
    { text: t('Sidebar.Informatique'), icon: null },
    { text: t('Sidebar.SignalementIT'), icon: <ComputerOutlined />, path: "/signalement", visible: roleInfo.Lecture_Signalement_Informatique },
    { text: t('Sidebar.IncidentIT'), icon: <BugReportIcon />, path: "/incident_it", visible: roleInfo.Lecture_Incident_Informatique },
    { text: t('Sidebar.ServicesIT'), icon: <WorkIcon />, path: "/service_it", visible: roleInfo.Lecture_Services_Informatique },
    { text: t('Sidebar.PortailIT'), icon: <DashboardIcon />, path: "/portail_it", visible: roleInfo.Lecture_Secteur && abonnementType !== "proprietaire" },  // Masqué pour "proprietaire"
    { text: t('Sidebar.SecteurIT'), icon: <HomeWorkIcon />, path: "/secteurit", visible: roleInfo.Lecture_Secteur },
    { text: t('Sidebar.Administration'), icon: null },
    { text: t('Sidebar.Secteur'), icon: <MapIcon />, path: "/secteur", visible: roleInfo.Lecture_Secteur },
    { text: t('Sidebar.Feedback'), icon: <FeedbackIcon />, path: "/feedback", visible: roleInfo.Lecture_Secteur && abonnementType !== "proprietaire" },  // Masqué pour "proprietaire"
    { text: t('Sidebar.Personnalisation'), icon: <AutoAwesomeIcon />, path: "/personnalisation", visible: roleInfo.Lecture_Personnalisation },
    { text: t('Sidebar.Partenaire'), icon: <HomeWorkIcon />, path: "/partenaire", visible: roleInfo.Lecture_Secteur && abonnementType !== "proprietaire" },  // Masqué pour "proprietaire"
    { text: t('Sidebar.Developer'), icon: <DeveloperModeIcon />, path: "/developer", visible: roleInfo.Lecture_Admin && abonnementType !== "proprietaire" },
    { text: t('Sidebar.Statistique'), icon: <TodayOutlined />, path: "/statistique", visible: roleInfo.Lecture_Statistique && abonnementType !== "proprietaire" },  // Masqué pour "proprietaire"
    { text: t('Sidebar.Admin'), icon: <AdminPanelSettingsOutlined />, path: "/admin", visible: roleInfo.Lecture_Admin },
  ];

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold">
                    HURFLYM
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.filter(item => item.visible !== false).map(({ text, icon, path }) => (
                icon ? (
                  <ListItem key={path} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(path);
                        setActive(path);
                      }}
                      sx={{
                        backgroundColor: active === path ? theme.palette.secondary[300] : "transparent",
                        color: active === path ? theme.palette.primary[600] : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color: active === path ? theme.palette.primary[600] : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === path && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                    {text}
                  </Typography>
                )
              ))}
            </List>
          </Box>

          <Box mb="2rem" mx="1rem">
            <Divider />
            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 2rem 2rem">
              <Button
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Box textAlign="left" sx={{ flex: 1, minWidth: 0 }}>
                  <Typography
                    fontWeight="bold"
                    fontSize="0.9rem"
                    sx={{ color: theme.palette.secondary[100], whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '140px' }}
                  >
                    {user.name}
                  </Typography>
                  <Typography
                    fontSize="0.8rem"
                    sx={{ color: theme.palette.secondary[200], whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '140px' }}
                  >
                    {user.email}
                  </Typography>
                </Box>
                <ArrowDropDownOutlined sx={{ color: theme.palette.secondary[300], fontSize: "25px" }} />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <MenuItem onClick={() => { handleClose(); handleLogout(); }}>
                  {t('Sidebar.Deconnexion')}
                </MenuItem>
              </Menu>
            </FlexBetween>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
