import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import Navbar from '../../components_public/navbar.jsx'; // Importing Navbar

const sections = [
  'Introduction',
  'Secteur',
  'Signalement',
  'Equipement',
  'Equipement Particulier',
  'Personnalisation',
  'Service',
  'Feedback',
  'Portails',
  'Administration',
  'Statistique',
  'API',
];

// Style for the selected circles (ronds)
const Circle = styled('div')(({ selected }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: selected ? '#001F54' : 'gray', // Change color based on selection
  position: 'absolute',
  left: '10px', // Position circle to the left
  marginLeft: '-5px', // Slight adjustment to move to the left
  transition: 'background-color 0.3s ease',
}));

const Documentation = () => {
  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] = useState('Introduction');
  const sectionRefs = useRef({});

  useEffect(() => {
    // Initialize IntersectionObserver
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSelectedSection(entry.target.id);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.6, // Trigger when 60% of section is visible
      }
    );

    // Observe each section
    sections.forEach((section) => {
      const element = sectionRefs.current[section];
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      // Clean up the observers when no longer needed
      sections.forEach((section) => {
        const element = sectionRefs.current[section];
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    const element = sectionRefs.current[section];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderContent = (section) => {
    switch (section) {
      case 'Introduction':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.IntroductionText')}</Typography>;
      case 'Secteur':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.SecteurText')}</Typography>;
      case 'Signalement':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.SignalementText')}</Typography>;
      case 'Equipement':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.EquipementText')}</Typography>;
      case 'Equipement Particulier':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.EquipementParticulierText')}</Typography>;
      case 'Personnalisation':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.PersonnalisationText')}</Typography>;
      case 'Service':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.ServiceText')}</Typography>;
      case 'Feedback':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.FeedbackText')}</Typography>;
      case 'Portails':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.PortailsText')}</Typography>;
      case 'Administration':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.AdministrationText')}</Typography>;
      case 'Statistique':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.StatistiqueText')}</Typography>;
      case 'API':
        return <Typography sx={{ color: 'black' }}>{t('Documentation.APIText')}</Typography>;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        height: '100vh', // Full page height
        overflow: 'hidden', // No scroll on the entire page
      }}
    >
      {/* Navbar at the top */}
      <Navbar />

      <Box
        sx={{
          height: 'calc(100% - 70px)', // Adjust height to take into account Navbar height
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          pt: '1%', // Adjusted top padding (moved up by 4%)
        }}
      >
        {/* Starry Background */}
        {[...Array(60)].map((_, i) => (
          <Box
            key={i}
            sx={{
              position: 'absolute',
              width: '3px',
              height: '3px',
              backgroundColor: 'white',
              borderRadius: '50%',
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `twinkle ${Math.random() * 5 + 3}s infinite`,
              opacity: Math.random() * 0.8 + 0.2,
              filter: 'blur(1px)',
            }}
          />
        ))}

        <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center' }}>
          {/* White Box */}
          <Box
            sx={{
              width: '80%',
              height: '90%',
              backgroundColor: 'white',
              borderRadius: '15px',
              boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
              overflow: 'hidden', // Prevent overflow on the white box
            }}
          >
            {/* General Title */}
            <Typography variant="h3" sx={{ color: '#001F54', fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
              {t('Documentation.Titre')}
            </Typography>

            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              {/* Table of contents on the left */}
              <Box
                sx={{
                  width: '20%',
                  position: 'relative',
                  padding: '20px',
                  borderRight: '1px solid #eee',
                }}
              >
                <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  {sections.map((section) => (
                    <ListItem
                      button
                      key={section}
                      onClick={() => handleSectionClick(section)}
                      selected={selectedSection === section}
                      sx={{
                        padding: '5px 0',
                        color: selectedSection === section ? '#001F54' : 'gray',
                        cursor: 'pointer',
                        position: 'relative',
                        pl: 4,
                        mb: '-2px', // Small margin for better spacing
                      }}
                    >
                      <Circle selected={selectedSection === section} />
                      <ListItemText primary={t(`Documentation.${section}`)} />
                    </ListItem>
                  ))}
                </List>
              </Box>

              {/* Content on the right */}
              <Box
                sx={{
                  width: '80%',
                  height: '500px',
                  overflowY: 'auto', // Overflow scrolling within the white box
                  padding: '20px',
                }}
              >
                {sections.map((section) => (
                  <Box
                    id={section}
                    key={section}
                    ref={(el) => (sectionRefs.current[section] = el)}
                    sx={{ mb: 6 }}
                  >
                    <Typography variant="h5" sx={{ mb: 2, color: '#001F54' }}>
                      {t(`Documentation.${section}`)}
                    </Typography>
                    {renderContent(section)}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Documentation;
